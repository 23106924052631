<template>
    <div class="modal-basic">
        <div class="top">
            <span class="title" v-html="$translate('PREMIUM_COMMENT')" />
            <i class="zmdi zmdi-close" @click="$emit('close')" />
        </div>
        <div class="desc" v-html="$translate('PREMIUM_COMMENT_DESC')" />
        <TextareaWithAutoTexts v-model="recommendMessage" class="bottom" />
        <div class="btn-container">
            <button :class="{ disabled: !recommendMessage }" @click="saveAgentIntro" v-html="$translate('SAVE')" />
        </div>
    </div>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'ModalPremiumComment',
    data: () => ({
        recommendMessage: null,
    }),
    props: {
        options: Object,
    },
    computed: {
        user() {
            return this.chat.user
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        premiumProduct() {
            if (this.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.user, 'premium')
                return premium
            }
            return null
        },
    },
    mounted() {
        this.recommendMessage = this.options.agent_intro
    },
    methods: {
        async saveAgentIntro() {
            const premiumProductId = this.premiumProduct.id
            try {
                const payload = {
                    product_id: premiumProductId,
                    agent_intro: this.recommendMessage,
                }
                // store에 코멘트 작성 내용 저장
                const resp = await productService.updateAgentIntro(payload)
                const user = this.$store.getters.userLists.find(u => u.id === this.user.id)
                user.$$premium.premium_info.agent_intro = this.recommendMessage

                this.$toast.success(resp.msg)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    min-width: 480px;

    .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 18px;
            color: black;
            margin-top: 4px;
            margin-bottom: 8px;
            @include f-bold;
        }
    }

    .desc {
        margin-bottom: 28px;
    }

    ::v-deep .textarea {
        height: 480px !important;
    }

    .btn-container {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 40px;
        padding-top: 16px;
        border-top: 1px solid $grey-03;

        button {
            background-color: $purple-primary;
            color: white;
            font-size: 14px;
            border: transparent;
            font-weight: normal;
            padding: 8px 30px;
            border-radius: 8px;
            @include f-medium;

            &.disabled {
                background-color: $grey-05;
                color: $grey-03;
            }
        }
    }
}
</style>
